Vue.config.debug = true;
Vue.config.devtools = true;

Vue.mixin({
    created: function() {
        var qString = this.$options.testString;

        if (qString) {
            // console.log('qString:' + qString);
            var qStringVal = decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(qString).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
            // console.log('val:' + qStringVal);
            return qStringVal;
        }
    }
});

/* ------- vue components --------- */
var cmp_logo = require('./vue-components/cmp_logo');
var cmp_menu = require('./vue-components/cmp_menu');
var cmp_nav = require('./vue-components/cmp_nav');
var cmp_contentheader = require('./vue-components/cmp_content-header');
var cmp_pin = require('./vue-components/cmp_pin');
var cmp_event = require('./vue-components/cmp_events');

new Vue({
    el: '#template--header'
});