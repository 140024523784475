
/* including Google Analytics directly here so we have access to ga() method locally in this module */

(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-7941362-17', 'auto');
ga('send', 'pageview');

/* -------- analytics methods ------------ */
function trackEvent(cat, act, label) {
    ga('send', 'event', {
        eventCategory: cat,
        eventAction: act,
        eventLabel: label
    });
}

function startSession() {
    ga('send','pageview', {
        'sessionControl': 'start'
    });
}

function endSession() {
    ga('send','pageview', {
        'sessionControl': 'end'
    });
}

require('./geopoint.js'); // geodata parsing library

/* ------------- page embed status for standalone/kiosk display ------------- */

var pageEmbedStatus = false;

function getQueryStringValue (key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

var pageEmbed = getQueryStringValue('pageEmbed');

if ( pageEmbed ) {
    pageEmbedStatus = true;
    document.body.classList.add('page-embed');
    document.body.classList.add('idle');
}

/* --------- idle timers --------------------------------------------- */

var Timer = require('timer.js');

var idleTimerLength = 30;
var resetTimerLength = 30;

var idleState = false;
var idleElem = document.getElementById('idle-prompt__container');

var idleTimer = new Timer({ // short timer for simplified interaction prompt
    // tick    : 1,
    // ontick  : function(ms) { console.log(ms + ' milliseconds left') },
    // onstart : function() { console.log('timer started') },
    // onstop  : function() { console.log('timer stop') },
    // onpause : function() { console.log('timer set on pause') },
    onend   : function() {
        showIdleState();
        idleState = true;
        resetTimer.start(resetTimerLength);
    }
});

var resetTimer = new Timer({
    onend: function() {
        resetMap();
    }
});

function hideIdleState() { // touch interactions trigger hiding of any idle items and new session, if previously long idle
    // console.log('hide idle elem');
    idleElem.classList.remove('idle-state--active');
    document.body.classList.remove('idle');


    idleTimer.stop();
    idleTimer.start(idleTimerLength);

    if ( idleState ) { // start new session if idle
        idle = false;
        startSession();
    }
}

function showIdleState() { // if timer is not already running, wait for x seconds after touch (if new touch doesn't clear timeout)
    idleElem.classList.add('idle-state--active');
    document.body.classList.add('idle');
    endSession();
}


/* ------------------ map ------------------------------------------------ */

var map,
    resetMap,
    importId;

var popUp = null;

var popupScreen = document.getElementById('screen');
var popupActive = false;

/* -- handler for pin popup close --*/
var hideModalScreen = function() {
    popupScreen.classList.add('hidden');
    popupActive = false;
};

function removeNewWindowHrefs() { // for kiosk displays -- no outbound links
    var targetBlankHrefs = document.querySelectorAll('a[target=_blank]');
    var attribControls = document.getElementsByClassName('mapboxgl-ctrl-attrib');

    attribControls[0].setAttribute('style', 'display:none');

    for (var i=0; i<targetBlankHrefs.length; i++) { // try removing link functions
        targetBlankHrefs[i].removeAttribute('href');
        targetBlankHrefs[i].removeAttribute('target');
    }
}

// load map
// var mapboxgl = require('mapbox-gl')
var bounds = [
    [-81.0791015625, 39.52099229357195],
    [-68.7744140625, 47.301584511330795]
];

mapboxgl.accessToken = 'pk.eyJ1IjoiaGV3aXR0YmoiLCJhIjoiY2pkMXVyc3A2MjFicTJxcW9sZmNjM3VibCJ9.6neY7nJOg1MDb18cMmK7eQ';

if ( document.getElementById('map') ) {

    var layerControl_barge = document.getElementById('check--barge'),
        layerControl_crystal = document.getElementById('check--crystal');

    if (pageEmbed) {
        idleElem.classList.add('idle-state--active'); // set embedded map to idle state active

        idleElem.addEventListener('click', function(){
            hideIdleState();
        });
        }

    var appElem = document.getElementById('app');

    map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/hewittbj/cjcs49kov1om42smtzvhuitvu',
        pitchWithRotate: false,
        scrollZoom: false,
        touchZoomRotate: true,
        maxBounds: bounds,
        attributionControl: false
    });

    map.touchZoomRotate.disableRotation();
    map.dragRotate.disable();

    // set control elements
    var nav = new mapboxgl.NavigationControl({
        showCompass: false
    });

    map.addControl(nav, 'bottom-right');
    map.addControl(new mapboxgl.AttributionControl({
        compact: true}));

    map.on('load', function () {

        /* ----------- add geojson data sources --------------- */
        map.addSource('cluster-corning', {
            type: 'geojson',
            data: 'assets/data/corning-cluster-pin.geojson'
        });

        map.addSource('barge-route', {
            type: 'geojson',
            data: 'assets/data/barge-route_v1.0.geojson'
        });

        map.addSource('barge-pins', {
            type: 'geojson',
            data: 'assets/data/location-data--barge-pins.geojson'
        });

        map.addSource('barge-location', {
            type: 'geojson',
            data: 'assets/data/barge-location.geojson?v=201806250914'
        });

        map.addSource('crystal-city', {
            type: 'geojson',
            data: 'assets/data/location-data--crystal-city.geojson'
        });

        map.addSource('feeder-canal', {
            type: 'geojson',
            data: 'assets/data/chemung-feeder-canal.geojson'
        });

        /* ------- create layers from data sources ----------- */

        map.addLayer({
            'id': 'feeder-canal',
            'type': 'line',
            'source': 'feeder-canal',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round',
                'visibility': 'visible'
            },
            'paint': {
                'line-color': 'rgb(255, 164, 0)',
                'line-width': 5
            }
        });

        map.addLayer({
            'id': 'crystal-city',
            'type': 'symbol',
            'source': 'crystal-city',

            'layout': {
                'icon-image': '{marker-symbol}',
                'icon-offset': [0,-10],
                'icon-size': 0.4,
                'icon-allow-overlap': true,
                'visibility': 'visible'
            }
        });

        map.addLayer({
            'id': 'barge-route',
            'type': 'line',
            'source': 'barge-route',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round',
                'visibility': 'visible'
            },
            'paint': {
                'line-color': 'rgb(226, 35, 26)',
                'line-width': 5
            }
        });

        map.addLayer({
            'id': 'barge-pins',
            'type': 'symbol',
            'source': 'barge-pins',

            'layout': {
                'icon-image': '{marker-symbol}',
                'icon-offset': [0,-10],
                'icon-size': 0.4,
                'icon-allow-overlap': true,
                'visibility': 'visible'
            }
        });

        map.addLayer({
            'id': 'barge-location',
            'type': 'symbol',
            'source': 'barge-location',

            'layout': {
                'icon-image': 'barge--red',
                'icon-offset': [0,-10],
                'icon-size': 0.6,
                'icon-allow-overlap': true,
                'visibility': 'visible'
            }
        });

        map.addLayer({
            'id': 'cluster-corning',
            'type': 'symbol',
            'source': 'cluster-corning',
            'maxzoom': 9,

            'layout': {
                'icon-image': 'magnifier',
                'icon-offset': [10,5],
                'icon-size': 1.1,
                'text-field': 'Corning',
                'text-anchor': 'right',
                'text-offset': [-3.5,0],
                'icon-allow-overlap': false,
                'visibility': 'visible'
            },
            'paint': {
                'text-color': '#FFFFFF'
            }
        });


        /* --- handle pin clicks: generate popup
        // When a click event occurs on a feature in the appropriate layer, open a popup at the
        location of the feature, with description HTML loaded dynamically by ID
        (ID from location data properties is associated with in-page hidden content generated in Vue component */

        function clusterZoomHandler() {
            popupActive = false;
            map.off('zoomend', clusterZoomHandler);
        }

        function mapClickHandler(e, layer) {
            popupActive = true;

            if (pageEmbedStatus) { // any click on map elements hides idle state (only for kiosk maps)
                hideIdleState();
            }

            var feature = e.features[0];
            var importedDesc;

            // google analytics
            var gaCat = 'pin--' + layer,
                gaLabel = feature.properties.descriptionId;

            trackEvent(gaCat, 'open', gaLabel);

            switch(layer) {

                case 'cluster-corning':
                    map.on('zoomend', clusterZoomHandler);
                    map.fitBounds([
                        [-77.0881462097168,
                        42.133494685483676],
                        [-77.01810836791992,
                        42.16213099980754]
                    ]);

                    break;

                case 'barge-pins':
                    popupScreen.classList.remove('hidden');

                    importId = feature.properties.descriptionId;
                    importedDesc = document.getElementById(importId);

                    popUp = new mapboxgl.Popup()
                        .setLngLat(feature.geometry.coordinates)
                        .setHTML(importedDesc.outerHTML )
                        .addTo(map);

                    break;

                case 'barge-location':
                    popupScreen.classList.remove('hidden');

                    var coordinates = feature.geometry.coordinates;
                    /* set long/lat in pin text */
                    var long = coordinates[0],
                        lat = coordinates[1];

                    var point = new GeoPoint(long, lat);

                    document.getElementById('pin-content__long').textContent = point.getLonDeg();
                    document.getElementById('pin-content__lat').textContent = point.getLatDeg();

                    /* grab description from in-page hidden element */
                    importedDesc = document.getElementById('pin-content--gb-location');

                    popUp = new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(importedDesc.outerHTML )
                        .addTo(map);

                    break;

                case 'crystal-city':
                    popupScreen.classList.remove('hidden');

                    importId = feature.properties.descriptionId;
                    importedDesc = document.getElementById(importId);
                    popUp = new mapboxgl.Popup()
                        .setLngLat(feature.geometry.coordinates)
                        .setHTML(importedDesc.outerHTML )
                        .addTo(map);

                    break;

                default:
            }

            if (popUp) {

                popUp.on('close', function(e){
                    hideModalScreen();

                    if (pageEmbedStatus && !idleState) { // hide idle if on kiosk and NOT idle
                        hideIdleState();
                    }

                    // google analytics
                    if ( !idleState ) {
                        trackEvent(gaCat, 'close', gaLabel);
                    }
                });
            }
        }

        map.on('click', 'cluster-corning', function(e) {
            if(!popupActive) {
                mapClickHandler(e, 'cluster-corning');
            }
        });

        map.on('click', 'barge-pins', function(e){
            if(!popupActive) {
                mapClickHandler(e, 'barge-pins' );
            }
        });

        map.on('click', 'barge-location', function(e){
            if(!popupActive) {
                mapClickHandler(e, 'barge-location');
            }
        });

        map.on('click', 'crystal-city', function(e) {
            if(!popupActive) {
                mapClickHandler(e, 'crystal-city');
            }
        });


        /* hover pointer handling --------- */
        map.on('mouseenter', 'barge-pins', function () {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'barge-pins', function () {
            map.getCanvas().style.cursor = '';
        });

        map.on('mouseenter', 'barge-location', function () {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'barge-location', function () {
            map.getCanvas().style.cursor = '';
        });

        map.on('mouseenter', 'crystal-city', function () {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'crystal-city', function () {
            map.getCanvas().style.cursor = '';
        });

        map.on('zoom', function(){
            if ( pageEmbedStatus && !idleState ) {
                hideIdleState();
            }
        });

        map.on('contextmenu', function() {
            // console.log('contextmenu detected');
            return false;
        });

        if (pageEmbedStatus) {
            removeNewWindowHrefs();
        }
    });

    /* ----- toggle layers visibility ----------- */
    var mapLayerControls = document.getElementsByClassName('control--layer__item');

    for ( var i=0; i<mapLayerControls.length; i++ ) {
        var controlItem = mapLayerControls[i];

        controlItem.onchange = function(e) {
            // e.preventDefault();
            // e.stopPropagation();

            if (pageEmbedStatus && !idleState) {
                hideIdleState();
            }

            var itemId = e.target.dataset.layerid;


            // google analytics

            if ( !idleState ) { // if triggered by user event (rather than idle reset), send GA event
                trackEvent('layer-control', 'toggle-layer', itemId);
            }


            var visibility = map.getLayoutProperty(itemId, 'visibility');

            if ( itemId === 'barge-pins' ) {

                if (visibility === 'visible') {
                    map.setLayoutProperty('barge-pins', 'visibility', 'none');
                    map.setLayoutProperty('barge-route', 'visibility', 'none');
                    map.setLayoutProperty('barge-location', 'visibility', 'none');
                    layerControl_barge.checked = false;
                    layerControl_barge.removeAttribute('checked');
                    layerControl_barge.setAttribute('aria-checked', 'false');
                }

                else {
                    map.setLayoutProperty('barge-pins', 'visibility', 'visible');
                    map.setLayoutProperty('barge-route', 'visibility', 'visible');
                    map.setLayoutProperty('barge-location', 'visibility', 'visible');
                    layerControl_barge.checked = true;
                    layerControl_barge.setAttribute('checked', '');
                    layerControl_barge.setAttribute('aria-checked', 'true');

                }
            }

            else if (itemId === 'crystal-city' ) {

                if (visibility === 'visible') {
                    map.setLayoutProperty('crystal-city', 'visibility', 'none');
                    map.setLayoutProperty('feeder-canal', 'visibility', 'none');
                    map.setLayoutProperty('cluster-corning', 'visibility', 'none');
                    layerControl_crystal.checked = false;
                    layerControl_crystal.removeAttribute('checked');
                    layerControl_crystal.setAttribute('aria-checked', 'false');
                }

                else {
                    map.setLayoutProperty('crystal-city', 'visibility', 'visible');
                    map.setLayoutProperty('feeder-canal', 'visibility', 'visible');
                    map.setLayoutProperty('cluster-corning', 'visibility', 'visible');
                    layerControl_crystal.checked = true;
                    layerControl_crystal.setAttribute('checked', '');
                    layerControl_crystal.setAttribute('aria-checked', 'true');
                }

            }

            else { /* error */ }
        };
    }

    var pinControlHeader = document.getElementById('control--layers__header');
    var pinControlsContainer = document.getElementById('control--layers__container--controls');

    function hidePinControl () { // hide pin visibility menu by default on very small screens
        pinControlsContainer.classList.add('hidden');
        pinControlHeader.classList.add('hidden');

        if (pageEmbedStatus) {
            hideIdleState();
        }
    }

    window.onload = function() {
        if (window.matchMedia("(max-width: 400px)").matches) {
            hidePinControl();
        }
    };

    window.onresize = function () {

        if (window.matchMedia("(max-width: 400px)").matches) {
            hidePinControl();
        }
    };

    pinControlHeader.onclick = function(e) {
        pinControlsContainer.classList.toggle('hidden');
        pinControlHeader.classList.toggle('hidden');

        if (pageEmbedStatus) {
            hideIdleState();
        }

        // google analytics
        trackEvent('layer-control', 'toggle-menu-visbility', '');
    };

    resetMap = function() {
        // reset map location
        map.easeTo({
            center: [-75.351259, 42.244847],
            zoom: 6.26,
            duration: 5000
        });

        // reset map contents and controls
        hideModalScreen();

        pinControlsContainer.classList.remove('hidden');
        pinControlHeader.classList.remove('hidden');

        map.setLayoutProperty('barge-pins', 'visibility', 'visible');
        map.setLayoutProperty('barge-route', 'visibility', 'visible');
        map.setLayoutProperty('barge-location', 'visibility', 'visible');
        map.setLayoutProperty('crystal-city', 'visibility', 'visible');
        map.setLayoutProperty('feeder-canal', 'visibility', 'visible');
        map.setLayoutProperty('cluster-corning', 'visibility', 'visible');

        layerControl_barge.checked = true;
        layerControl_barge.setAttribute('checked','');
        layerControl_barge.setAttribute('aria-checked', 'true');

        layerControl_crystal.checked = true;
        layerControl_crystal.setAttribute('checked', '');
        layerControl_crystal.setAttribute('aria-checked', 'true');

        if (popUp) {
            popUp.remove();
        }

        idleElem.classList.add('idle-state--active');

    };
}

// TODO: reset timers and GA events for pin tab clicks (and scroll?)

