
var pinContent = new Vue({
   el: '#container--pin-content',
   data: {
       locFeatures: [],
       ccFeatures: [],
       pageEmbed: getQueryStringValue('pageEmbed')
   },

    created: function () {
       this.fetchLocData();
       this.fetchCCData();
    },

    methods: {
       fetchLocData: function() {
           var d = this;
           fetch('./assets/data/location-data--barge-pins.geojson')
               .then(function(response) {
                   return response.json();
               })
               .then(function(myJson) {
                   d.locFeatures = myJson.features;
               }).then(function(){
                    // console.dir(d.features);
           });

       },
        fetchCCData: function() {
            var d = this;
            fetch('./assets/data/location-data--crystal-city.geojson')
                .then(function(response) {
                    return response.json();
                })
                .then(function(myJson) {
                    d.ccFeatures = myJson.features;
                }).then(function(){
                // console.dir(d.features);
            });

        },
        googleMapsUrl: function(coords) {
           var baseUrl = 'https://www.google.com/maps/dir/?api=1&destination=';
           return '' + baseUrl + coords[1] + '%2C' + coords[0];
        }
    }
});


/* pin tab control */
document.addEventListener('click', function (event) {
    if ( event.target.classList.contains( 'pin-content__tablist__tab' ) ) {
        var target = event.target;

        if ( !(target.classList.contains('active')) ) {
            var associatedContentId = target.getAttribute('aria-controls');
            var associatedTabPanel = document.getElementById(associatedContentId);
            var deactivatedTab = document.getElementById(target.dataset.deactivatetab);
            var deactivatedPanel = document.getElementById(target.dataset.deactivatepanel);

            /* activate tab */
            target.classList.add('active');
            target.setAttribute('aria-selected', 'true');

            /* activate tabpanel */
            associatedTabPanel.classList.add('active');
            associatedTabPanel.setAttribute('aria-hidden', 'false');

            /* deactivate other tab */
            deactivatedTab.classList.remove('active');
            deactivatedTab.setAttribute('aria-selected', 'false');

            /* deactivate other tabpanel */
            deactivatedPanel.classList.remove('active');
            deactivatedPanel.setAttribute('aria-hidden', 'true');
        }
    }
}, false);

function getQueryStringValue (key) {
    var qStringVal = decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));

    if (qStringVal == 'true') {
        return true;
    }

    return false;
}