Vue.component('v-header', {
   template:
   '<header :class="{pageEmbed : pageEmbed}">' +
       '<div class="title--app-name"><span class="title--app-name__part1">Past</span><span class="title--app-name__part2">Port</span></div>' +
       '<h1 v-if="!pageEmbed" class="title--page-name">{{headerText}}</h1>'+
   '</header>',
    props: ['headerText'],
    data: function() { // this holds the open/closed menu state
        return {
            pageEmbed: getQueryStringValue('pageEmbed')
        }
    }
});

function getQueryStringValue (key) {
    // console.log('query string:' + key);
    var qStringVal = decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));

    if (qStringVal == 'true') {
        // console.log('qStringVa');
        return true;
    }

    return false;
}