// var moment = require('moment');

var eventContent = new Vue({
    el: '#container--content--events',
    data: {
        locFeatures: [],
    },

    created: function () {
        this.fetchLocData();
    },

    methods: {

        fetchLocData: function() {
            var d = this;
            fetch('./assets/data/location-data--barge-pins.geojson')
                .then(function(response) {
                    return response.json();
                })
                .then(function(myJson) {
                    d.locFeatures = myJson.features;
                }).then(function(){
                    // nothing
            });
        },

        dateCompare: function(startDate, endDate) {
            stDate = moment(startDate, 'YYYY-MM-DD');
            eDate = moment(endDate, 'YYYY-MM-DD');

            return( moment().isBetween(startDate, endDate) );
        }
    }
});

Vue.filter('formatDate', function(value, format) { // return different date formats
    if (value) {

        switch (format) {
            case 'time':
                return moment(String(value)).format('h:mm');
                break;

            case 'date':
                return moment(String(value)).format('M/D');
                break;

            case 'datestr':
                return moment(String(value)).format('MDh-mm');
                break;

            case 'y-m-d':
                return moment(String(value)).format('YYYY-MM-DD');
                break;

            default:
                return moment(String(value)).format('MD');

        }
    }
});

var scrollToLinks = document.getElementsByClassName('link--scrollto');

if ( scrollToLinks.length >= 1 ) {

    for (var i=0; i<scrollToLinks.length; i++) {

        var anchorHref = scrollToLinks[i].href;
        var hashPos = anchorHref.indexOf('#');
        var anchorId = anchorHref.slice(hashPos+1);
        var scrollTargetElemArr = document.querySelectorAll('[name=' + anchorId + ']');
        var linkElem = scrollToLinks[i];

        linkElem.onclick = function(){

            scrollTargetElemArr[0].scrollIntoView({ // note that options may not work on all browsers
                block: 'start',
                behavior: 'smooth'
            });

            return false;
        };

    }
}