Vue.component('v-logo', {
    template:'<svg version="1.1" id="cmog-logo-horizontal" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1138.5 100" xml:space="preserve">' +
    '<path d="M911.8,6.9c-2.2-2-4.7-3.4-7.5-4.4c-2.8-1-5.6-1.5-8.4-1.5c-2.8,0-5.6,0.5-8.4,1.5c-2.8,1-5.3,2.5-7.5,4.4 c-2.2,2-4,4.4-5.4,7.4c-1.4,3-2.1,6.5-2.1,10.4v51.4c0,4,0.7,7.5,2.1,10.5c1.4,3,3.2,5.4,5.4,7.4c2.2,2,4.7,3.4,7.5,4.4 c2.8,1,5.6,1.5,8.4,1.5c2.8,0,5.6-0.5,8.4-1.5c2.8-1,5.3-2.5,7.5-4.4c2.2-2,4-4.4,5.4-7.4c1.4-3,2.1-6.4,2.1-10.5V45.5h-25v12.3 h11.1v18.3c0,3.4-0.9,5.9-2.8,7.4c-1.9,1.6-4.1,2.4-6.6,2.4c-2.6,0-4.8-0.8-6.6-2.4c-1.9-1.6-2.8-4.1-2.8-7.4V24.8 c0-3.4,0.9-5.9,2.8-7.4c1.9-1.6,4.1-2.4,6.6-2.4c2.5,0,4.8,0.8,6.6,2.4c1.9,1.6,2.8,4.1,2.8,7.4v4.9h13.9v-4.9 c0-3.9-0.7-7.4-2.1-10.4C915.8,11.4,914,8.9,911.8,6.9"/>' +
    '<path d="M992.1,1.8l-18.6,83.3h-26.6V1.8h-13.9v97.3h37.4h4.1h9.8l4.1-20.9h19l4.1,20.9h13.9l-21.7-97.3H992.1z M990.8,65.1 l6.8-35.3h0.3l6.8,35.3H990.8z"/>' +
    '<path d="M1072.3,8c-2-2.1-4.5-3.8-7.4-5.1c-2.9-1.3-6-1.9-9.5-1.9c-4,0-7.5,0.7-10.5,2.1c-3,1.4-5.5,3.3-7.4,5.7 c-2,2.4-3.4,5.2-4.4,8.5c-1,3.3-1.5,6.7-1.5,10.4c0,6.1,1,11.2,3.1,15.2c2.1,4.1,6.1,7.3,12.1,9.8l10,4.1c1.7,0.7,3.1,1.4,4.2,2 c1,0.6,1.9,1.5,2.5,2.5c0.6,1,1,2.3,1.2,4c0.2,1.6,0.3,3.7,0.3,6.3c0,2.2-0.1,4.2-0.3,6c-0.2,1.8-0.6,3.3-1.3,4.6 c-0.7,1.2-1.7,2.2-3,2.9c-1.3,0.7-3.2,1-5.5,1c-3.6,0-6.1-1-7.6-3.1c-1.5-2-2.3-4.3-2.3-6.9v-4.4h-13.9v5.2c0,3.3,0.6,6.3,1.8,9.2 c1.2,2.8,2.9,5.3,5,7.3c2.1,2.1,4.6,3.7,7.5,4.9c2.9,1.2,6.1,1.8,9.4,1.8c4.5,0,8.2-0.7,11.3-2.2c3.1-1.5,5.6-3.4,7.4-5.9 c1.9-2.5,3.2-5.5,4-9c0.8-3.5,1.2-7.3,1.2-11.4c0-3.6-0.2-6.7-0.5-9.5c-0.4-2.8-1.1-5.2-2.1-7.4c-1-2.1-2.5-4-4.4-5.6 c-1.9-1.6-4.4-3-7.5-4.2l-10.7-4.2c-1.8-0.7-3.3-1.5-4.3-2.2c-1-0.7-1.8-1.6-2.4-2.5c-0.5-1-0.9-2.1-1-3.4c-0.1-1.3-0.2-2.9-0.2-4.9 c0-1.6,0.1-3.2,0.4-4.7c0.3-1.5,0.8-2.8,1.4-4c0.7-1.1,1.6-2.1,2.9-2.8c1.2-0.7,2.8-1.1,4.7-1.1c3.6,0,6.1,1.2,7.6,3.5 c1.5,2.3,2.3,5.1,2.3,8.3v3.1h13.9V23c0-2.7-0.5-5.4-1.6-8C1076,12.4,1074.4,10.1,1072.3,8"/>' +
    '<path d="M1135.5,29.8V23c0-2.7-0.5-5.4-1.6-8c-1.1-2.6-2.7-4.9-4.7-7c-2-2.1-4.5-3.8-7.4-5.1c-2.9-1.3-6-1.9-9.5-1.9 c-4,0-7.5,0.7-10.5,2.1c-3,1.4-5.5,3.3-7.4,5.7c-2,2.4-3.4,5.2-4.4,8.5c-1,3.3-1.5,6.7-1.5,10.4c0,6.1,1,11.2,3.1,15.2 c2.1,4.1,6.1,7.3,12.1,9.8l10,4.1c1.7,0.7,3.1,1.4,4.2,2.1c1,0.6,1.9,1.5,2.5,2.5c0.6,1,1,2.3,1.2,4c0.2,1.6,0.3,3.7,0.3,6.3 c0,2.2-0.1,4.2-0.3,6c-0.2,1.8-0.6,3.3-1.3,4.6c-0.7,1.2-1.7,2.2-3,2.9c-1.3,0.7-3.2,1-5.5,1c-3.6,0-6.1-1-7.6-3.1 c-1.5-2.1-2.3-4.3-2.3-6.9v-4.4h-13.9v5.2c0,3.3,0.6,6.3,1.8,9.2c1.2,2.8,2.9,5.3,5,7.3c2.1,2,4.6,3.7,7.5,4.8 c2.9,1.2,6.1,1.8,9.4,1.8c4.5,0,8.2-0.7,11.3-2.2c3.1-1.5,5.6-3.4,7.4-5.9c1.9-2.5,3.2-5.5,4-9c0.8-3.5,1.2-7.3,1.2-11.4 c0-3.6-0.2-6.7-0.5-9.5c-0.4-2.8-1.1-5.2-2.1-7.4c-1-2.1-2.5-4-4.4-5.6c-1.9-1.6-4.4-3-7.5-4.2l-10.7-4.2c-1.8-0.7-3.3-1.5-4.3-2.2 c-1-0.7-1.8-1.6-2.4-2.5c-0.5-1-0.9-2.1-1-3.4c-0.1-1.3-0.2-2.9-0.2-4.8c0-1.6,0.1-3.2,0.4-4.7c0.3-1.5,0.8-2.8,1.4-4 c0.7-1.1,1.6-2.1,2.9-2.8c1.2-0.7,2.8-1.1,4.7-1.1c3.6,0,6.1,1.2,7.6,3.5c1.5,2.3,2.3,5.1,2.3,8.3v3.1H1135.5z"/>' +
    '<path d="M776.6,44.8c0-2.7,0.5-5.1,1.4-7.2c1-2.1,2.2-3.8,3.8-5.2c1.6-1.4,3.3-2.4,5.2-3.1c1.9-0.7,3.9-1,5.9-1c2,0,3.9,0.4,5.9,1 c1.9,0.7,3.7,1.7,5.2,3.1c1.6,1.4,2.8,3.1,3.8,5.2c1,2.1,1.4,4.5,1.4,7.2v35.8c0,2.8-0.5,5.2-1.4,7.3c-1,2.1-2.2,3.8-3.8,5.1 c-1.6,1.4-3.3,2.4-5.2,3.1c-1.9,0.7-3.9,1-5.9,1c-2,0-3.9-0.3-5.9-1c-1.9-0.7-3.7-1.7-5.2-3.1c-1.6-1.4-2.8-3.1-3.8-5.1 c-1-2.1-1.4-4.5-1.4-7.3V44.8z M786.3,80.6c0,2.4,0.6,4.1,2,5.2c1.3,1.1,2.8,1.7,4.6,1.7c1.8,0,3.3-0.6,4.6-1.7c1.3-1.1,2-2.8,2-5.2 V44.8c0-2.4-0.6-4.1-2-5.2c-1.3-1.1-2.8-1.7-4.6-1.7c-1.8,0-3.3,0.6-4.6,1.7c-1.3,1.1-2,2.8-2,5.2V80.6z"/>' +
    '<polygon points="820.2,28.7 849.1,28.7 849.1,37.9 829.9,37.9 829.9,58.5 846.7,58.5 846.7,67.6 829.9,67.6 829.9,96.6 820.2,96.6"/>' +
    '<path d="M46.6,75.3c0,3-0.6,5.8-1.7,8.5c-1.1,2.7-2.7,5.1-4.7,7.1c-2,2-4.3,3.6-7,4.9c-2.7,1.2-5.5,1.8-8.6,1.8 c-2.6,0-5.3-0.4-8-1.1c-2.7-0.7-5.1-2-7.3-3.7c-2.2-1.8-3.9-4.1-5.3-7C2.7,83,2,79.3,2,74.9v-53c0-3.1,0.5-6.1,1.6-8.8 c1.1-2.7,2.6-5,4.6-7c2-2,4.3-3.5,7.1-4.7c2.7-1.1,5.8-1.7,9.1-1.7c3.2,0,6.1,0.6,8.8,1.7c2.7,1.1,5,2.7,7,4.7c2,2,3.5,4.5,4.7,7.3 c1.1,2.8,1.7,5.9,1.7,9.3v5.4H32.8v-4.6c0-2.7-0.8-5-2.3-7c-1.5-2-3.6-3-6.2-3c-3.4,0-5.7,1.1-6.8,3.2c-1.1,2.1-1.7,4.8-1.7,8v49.2 c0,2.8,0.6,5.1,1.8,7c1.2,1.9,3.4,2.8,6.6,2.8c0.9,0,1.9-0.2,2.9-0.5c1-0.3,2-0.8,2.8-1.6c0.9-0.7,1.6-1.7,2.1-3 c0.5-1.3,0.8-2.8,0.8-4.7v-4.7h13.8V75.3z"/>' +
    '<path d="M56.4,23.3c0-3.9,0.7-7.3,2-10.3c1.4-3,3.1-5.4,5.3-7.4c2.2-1.9,4.7-3.4,7.4-4.4c2.7-1,5.5-1.5,8.3-1.5 c2.8,0,5.6,0.5,8.3,1.5c2.7,1,5.2,2.5,7.4,4.4c2.2,1.9,4,4.4,5.3,7.4c1.4,3,2,6.4,2,10.3v50.8c0,4-0.7,7.4-2,10.3 c-1.4,2.9-3.1,5.4-5.3,7.3c-2.2,1.9-4.7,3.4-7.4,4.4c-2.8,1-5.5,1.5-8.3,1.5c-2.8,0-5.6-0.5-8.3-1.5c-2.7-1-5.2-2.5-7.4-4.4 c-2.2-1.9-4-4.4-5.3-7.3c-1.4-2.9-2-6.4-2-10.3V23.3z M70.2,74.1c0,3.3,0.9,5.8,2.8,7.4c1.8,1.6,4,2.4,6.6,2.4 c2.5,0,4.7-0.8,6.6-2.4c1.8-1.6,2.8-4,2.8-7.4V23.3c0-3.3-0.9-5.8-2.8-7.4c-1.8-1.6-4-2.4-6.6-2.4c-2.5,0-4.7,0.8-6.6,2.4 c-1.8,1.6-2.8,4-2.8,7.4V74.1z"/>' +
    '<path d="M114.3,0.6h22.2c16.2,0,24.3,9.4,24.3,28.2c0,5.6-0.9,10.4-2.6,14.4c-1.8,4-4.8,7.2-9.3,9.7l14.9,43.9h-14.6l-12.8-41.1 h-8.2v41.1h-13.8V0.6z M128.1,43.5h7.8c2.4,0,4.4-0.3,5.8-1c1.4-0.7,2.5-1.6,3.3-2.9c0.8-1.3,1.3-2.8,1.6-4.7c0.3-1.8,0.4-4,0.4-6.4 c0-2.4-0.1-4.6-0.4-6.4c-0.3-1.8-0.8-3.4-1.7-4.7c-0.9-1.3-2-2.3-3.6-2.9c-1.5-0.6-3.6-0.9-6.1-0.9h-7.2V43.5z"/>' +
    '<polygon points="170.8,0.6 184,0.6 204.8,58.5 205.1,58.5 205.1,0.6 218.9,0.6 218.9,96.8 205.9,96.8 184.8,38.9 184.5,38.9 184.5,96.8 170.8,96.8 "/>' +
    '<rect x="231.2" y="0.6" width="13.8" height="96.2"/>' +
    '<polygon points="257.2,0.6 270.5,0.6 291.3,58.5 291.6,58.5 291.6,0.6 305.3,0.6 305.3,96.8 292.4,96.8 271.3,38.9 271,38.9 271,96.8 257.2,96.8 "/>' +
    '<path d="M316.4,23.3c0-3.9,0.7-7.3,2-10.3c1.4-3,3.1-5.4,5.3-7.4c2.2-1.9,4.7-3.4,7.4-4.4c2.7-1,5.5-1.5,8.3-1.5 c2.8,0,5.6,0.5,8.3,1.5c2.7,1,5.2,2.5,7.4,4.4c2.2,1.9,4,4.4,5.3,7.4c1.4,3,2,6.4,2,10.3v4.9h-13.8v-4.9c0-3.3-0.9-5.8-2.8-7.4 c-1.8-1.6-4-2.4-6.6-2.4c-2.5,0-4.7,0.8-6.6,2.4c-1.8,1.6-2.8,4-2.8,7.4v50.8c0,3.3,0.9,5.8,2.8,7.4c1.8,1.6,4,2.4,6.6,2.4 c2.5,0,4.7-0.8,6.6-2.4c1.8-1.6,2.8-4,2.8-7.4V56h-10.9V43.8h24.7v30.3c0,4-0.7,7.4-2,10.3c-1.4,2.9-3.1,5.4-5.3,7.3 c-2.2,1.9-4.7,3.4-7.4,4.4c-2.7,1-5.5,1.5-8.3,1.5c-2.8,0-5.6-0.5-8.3-1.5c-2.7-1-5.2-2.5-7.4-4.4c-2.2-1.9-4-4.4-5.3-7.3 c-1.4-2.9-2-6.4-2-10.3V23.3z"/>' +
    '<path d="M422.6,52.9h-0.3L404.5,1.7h-13.3v96.7h13.9V39.6h0.3c0,0,17.4,52.2,17.3,52.1c-0.1-0.1,16.9-52.1,16.9-52.1h0.3v58.8h13.9 V1.7h-13.4L422.6,52.9z"/>' +
    '<path d="M497.7,75.3c0,3.4-0.8,6-2.4,7.6c-1.6,1.6-3.7,2.4-6.1,2.4c-2.4,0-4.5-0.8-6.1-2.4c-1.6-1.6-2.4-4.2-2.4-7.6V1.7h-13.9v75 c0,3.2,0.6,6.1,1.8,8.8c1.2,2.7,2.8,5.1,4.8,7.1c2,2,4.4,3.6,7.1,4.8c2.7,1.2,5.6,1.8,8.7,1.8c3.1,0,6-0.6,8.7-1.8 c2.7-1.2,5.1-2.8,7.1-4.8c2-2,3.6-4.4,4.8-7.1c1.2-2.7,1.8-5.7,1.8-8.8v-75h-13.9V75.3z"/>' +
    '<path d="M563.1,7.8c-2-2.1-4.5-3.8-7.3-5c-2.9-1.3-6-1.9-9.4-1.9c-4,0-7.5,0.7-10.5,2.1c-3,1.4-5.5,3.3-7.4,5.6 c-1.9,2.4-3.4,5.2-4.4,8.4c-1,3.3-1.5,6.7-1.5,10.3c0,6.1,1,11.1,3.1,15.1c2,4,6,7.3,12,9.7l9.9,4.1c1.7,0.7,3.1,1.4,4.1,2 c1,0.6,1.9,1.5,2.4,2.4c0.6,1,1,2.3,1.2,3.9c0.2,1.6,0.3,3.7,0.3,6.2c0,2.2-0.1,4.2-0.3,6c-0.2,1.8-0.6,3.3-1.3,4.6 c-0.7,1.2-1.7,2.2-3,2.9c-1.3,0.7-3.1,1-5.5,1c-3.5,0-6-1-7.5-3.1c-1.5-2-2.2-4.3-2.2-6.9v-4.3h-13.9v5.2c0,3.3,0.6,6.3,1.8,9.1 c1.2,2.8,2.9,5.2,5,7.3c2.1,2,4.6,3.6,7.5,4.8c2.9,1.2,6,1.8,9.4,1.8c4.4,0,8.2-0.7,11.3-2.2c3.1-1.4,5.5-3.4,7.4-5.9 c1.9-2.5,3.2-5.5,4-9c0.8-3.5,1.2-7.3,1.2-11.3c0-3.5-0.2-6.7-0.5-9.4c-0.4-2.8-1.1-5.2-2.1-7.3c-1-2.1-2.5-4-4.4-5.6 c-1.9-1.6-4.4-3-7.5-4.1l-10.6-4.2c-1.8-0.7-3.2-1.4-4.3-2.2c-1-0.7-1.8-1.6-2.4-2.5c-0.5-1-0.9-2.1-1-3.4c-0.1-1.3-0.2-2.9-0.2-4.8 c0-1.6,0.1-3.2,0.4-4.7c0.3-1.5,0.7-2.8,1.4-3.9c0.7-1.1,1.6-2.1,2.9-2.8c1.2-0.7,2.8-1.1,4.7-1.1c3.5,0,6,1.2,7.5,3.5 c1.5,2.3,2.2,5,2.2,8.2v3.1h13.9v-6.8c0-2.7-0.5-5.4-1.6-7.9C566.7,12.2,565.2,9.9,563.1,7.8"/>' +
    '<polygon points="579.8,98.4 621.1,98.4 621.1,84.5 593.6,84.5 593.6,56.2 617.5,56.2 617.5,43.1 593.6,43.1 593.6,14.7 621.1,14.7 621.1,1.7 579.8,1.7 "/>' +
    '<path d="M661,75.3c0,3.4-0.8,6-2.4,7.6c-1.6,1.6-3.7,2.4-6.1,2.4c-2.4,0-4.5-0.8-6.1-2.4c-1.6-1.6-2.4-4.2-2.4-7.6V1.7H630v75 c0,3.2,0.6,6.1,1.8,8.8c1.2,2.7,2.8,5.1,4.8,7.1c2,2,4.4,3.6,7.1,4.8c2.7,1.2,5.6,1.8,8.7,1.8c3.1,0,6-0.6,8.7-1.8 c2.7-1.2,5.1-2.8,7.1-4.8c2-2,3.6-4.4,4.8-7.1c1.2-2.7,1.8-5.7,1.8-8.8v-75H661V75.3z"/>' +
    '<path d="M736.9,1.7l-17.7,51.2H719L701.2,1.7h-13.3v96.7h13.9V39.6h0.3c0,0,17.3,52.2,17.2,52.1c-0.1-0.1,17-52.1,17-52.1h0.3v58.8 h13.9V1.7H736.9z"/>' +
    '</svg>'
});

