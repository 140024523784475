Vue.component('v-menulist', {
    template:
    '<div id="menu--list">' +
    '<ul>' +
    '<li><a href="map.html">Map</a></li>' +
    '<li><a href="crystalcity.html">Background Info</a></li> ' +
    '<li><a href="glassbarge.html">About GlassBarge</a></li> ' +
    '<li><a href="schedule.html">Schedule</a></li> ' +
    '<li><a href="social.html">Join the Conversation</a></li> ' +
    '<li><a href="partners.html">Partners & Sponsors</a></li> ' +
    '<li><a href="resources.html">Links & Resources</a></li> ' +
    '</ul> ' +
    '</div>'
});