
Vue.component('v-nav', {

    template:
        '<div v-if="!pageEmbed">'+
        '<nav id="menu" aria-labelledby="menu--control" role="navigation" :class="{menuActive : menuActive}">'+
        '<div id="menu--control" aria-controls="menu">' +
        '<a href="#" @click.stop.prevent="toggleMenuState"><i class="icon--menu">' +
        '<svg version="1.1" id="icon--menu" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"><rect class="menu-line menu-line--top" y="3.5" width="100" height="12"/><rect class="menu-line menu-line--middle" y="44.2" width="100" height="12"/><rect class="menu-line menu-line--bottom" y="84" width="100" height="12"/></svg>' +
        '</i>menu</a>' +
        '</div>' +
        '<div id="menu--logo">'+
        '<a href="https://www.cmog.org"><v-logo></v-logo></a>' +
        '</div>' +
        '<div>' +
        '<v-menulist></v-menulist>' +
        '</div></nav>' +
        '</div>',

    data: function() { // this holds the open/closed menu state
        return {
            menuActive: false,
            pageEmbed: getQueryStringValue('pageEmbed')
        }
    },

    methods: {
        toggleMenuState: function (e) {
            this.menuActive = !this.menuActive;
            setHeaderClass();

            // google analytics
            ga('send', {
                hitType: 'event',
                eventCategory: 'navigation',
                eventAction: 'toggle-nav-menu-visibility',
                eventLabel: ''
            });
        }
    }
});

function setHeaderClass() {
    var headerElem = document.getElementById('template--header');
    headerElem.classList.toggle('menuActive');
}

// check queryString (used to hide menu)
function getQueryStringValue (key) {
    var qStringVal = decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));

    if (qStringVal == 'true') {
        return true;
    }

    return false;
}


